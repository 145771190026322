import { NgClass } from '@angular/common';
import { Component, type OnInit, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { PAGE_URL } from '@core/constants/page-url';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { type ServerError } from '@core/models/serverError';
import { ToastrService } from '@core/services/toastr.service';

import { PasswordInputComponent } from '../../core/components/password-input/password-input.component';
import { AuthService } from '../auth.service';

@UntilDestroy()
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  standalone: true,
  imports: [
    AssetSrcDirective,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    PasswordInputComponent,
    ButtonLoadingDirective,
    RouterLink,
    TranslateModule,
  ],
})
export class ResetPasswordComponent implements OnInit {
  private readonly authService = inject(AuthService);
  private readonly router = inject(Router);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly toastr = inject(ToastrService);
  public form: UntypedFormGroup;
  public isPasswordReset = false;
  public isLoading = false;
  public isLoaded = false;
  private token: string;

  public ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.queryParams.token;
    if (!this.token) {
      this.router.navigate([PAGE_URL.LOGIN]);
      return;
    }

    this.authService
      .verifyForgotPassToken(this.token)
      .pipe(
        untilDestroyed(this),
        map(() => {
          this.isLoaded = true;
          this.form = new UntypedFormGroup({
            password: new UntypedFormControl('', [Validators.required, Validators.minLength(12)]),
          });
        }),
        catchError((err) => {
          this.router.navigate(['/passwordResetLinkExpired']);
          return of(err);
        }),
      )
      .subscribe();
  }

  public resetPassword(): void {
    if (this.form.invalid) {
      return;
    }

    this.isLoading = true;
    const form = this.form.value;
    this.authService
      .resetPassword(form.password, this.token)
      .pipe(
        untilDestroyed(this),
        map(() => (this.isPasswordReset = true)),
        catchError((err) => {
          err.error.errors.forEach((err: ServerError) => {
            if (!err.property || err.property === 'token') {
              this.toastr.serverError(err);
              return;
            }

            const field = this.form.get(err.property);
            if (field) {
              field.setErrors({
                ...field.errors,
                [err.errorCode]: err.errorMessage,
              });
            }
          });
          return of(err);
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe();
  }
}
