import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { AUDIT_TRAIL_BASE_URL } from '@clover/audit-trail/routes';
import { CAMPAIGNS_BASE_URL } from '@clover/campaigns-v2/routes';
import { UnreadCountersService } from '@clover/conversations-v4/workspaces/state/unread-counters/unread-counters.service';
import type { UserPermissions } from '@clover/core/services/permission.service';
import { UserService } from '@clover/core/services/user.service';
import { PRODUCTS_BASE_URL } from '@clover/products-v2/routes';
import { TASKS_BASE_URL } from '@clover/tasks-v2/routes';
import { WORKFLOWS_BASE_URL } from '@clover/workflows-v2/routes';
import { CONVERSATIONS_BASE_URL } from '@conversations/routes';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { SignalrService } from '@core/services/signalr.service';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { LimitNumberPipe } from '@design/chips/nav-counter-chip/limit-number.pipe';
import { NavCounterChipComponent } from '@design/chips/nav-counter-chip/nav-counter-chip.component';
import { TooltipPosition, TooltipSize } from '@design/overlays/tooltip/tooltip';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    AssetSrcDirective,
    FocusableDirective,
    TranslateModule,
    TooltipDirective,
    RouterLinkActive,
    NavCounterChipComponent,
    LimitNumberPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavComponent {
  protected readonly unreadConversations = inject(UnreadCountersService).totalUnreadCount;
  protected readonly unreadMessages = inject(SignalrService).totalUnreadMessages;
  protected readonly incompleteTasks = inject(SignalrService).totalIncompleteTasks;

  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;
  protected readonly TooltipPosition = TooltipPosition;
  protected readonly TooltipPadding = TooltipSize;

  protected readonly CONVERSATIONS_BASE_URL = CONVERSATIONS_BASE_URL;
  protected readonly PRODUCTS_BASE_URL = PRODUCTS_BASE_URL;
  protected readonly WORKFLOWS_BASE_URL = WORKFLOWS_BASE_URL;
  protected readonly TASKS_BASE_URL = TASKS_BASE_URL;
  protected readonly CAMPAIGNS_BASE_URL = CAMPAIGNS_BASE_URL;
  protected readonly AUDIT_TRAIL_BASE_URL = AUDIT_TRAIL_BASE_URL;

  private readonly userService = inject(UserService);

  get permissions(): UserPermissions {
    return this.userService.permissions;
  }
}
