<div class="auth-content">
  <div class="auth-page-header">
    <img
      appAssetSrc="assets/svg/common/logo.svg"
      alt="logo"
    />
  </div>

  <form
    [formGroup]="signInForm"
    class="login-form"
    #ngForm="ngForm"
    (ngSubmit)="handleFormSubmit()"
  >
    @if (isTimedOut) {
      <div class="login-timeout-message">
        <img
          appAssetSrc="assets/svg/common/warning-blue.svg"
          alt="warning"
        />
        <div>{{ 'signIn.timedOut' | translate }}</div>
      </div>
    }

    <h1>{{ 'signIn.welcome' | translate }}</h1>
    <h3>{{ 'signIn.signToAcc' | translate }}</h3>

    @if (passwordControl.disabled) {
      <div
        class="form-group mb-3"
        [class.has-error]="emailControl.touched && emailControl.invalid"
      >
        <label for="email">
          {{ 'common.controls.email.label' | translate }}
        </label>

        <input
          type="email"
          formControlName="email"
          id="email"
          [placeholder]="'common.controls.email.placeholder' | translate"
        />

        <form-error-messages
          [showErrors]="emailControl.touched"
          [control]="emailControl"
        />
      </div>
    } @else {
      <div class="form-group sign-in-email">
        <label>
          {{ 'signIn.signingInAs' | translate }}
        </label>

        <div class="sign-in-email__value">
          <div class="sign-in-email__value-email">
            {{ emailControl.value }}
          </div>

          <a
            href="#"
            (click)="returnToEmailEntry(); $event.preventDefault()"
          >
            {{ 'signIn.changeEmail' | translate }}
          </a>
        </div>

        @if (showResendEmail()) {
          <a
            href="#"
            (click)="resendEmail($event); $event.preventDefault()"
            class="resend-email-link"
          >
            {{ 'signIn.buttons.resendEmail' | translate }}
          </a>
        }
      </div>

      <div
        class="form-group"
        [class.has-error]="passwordControl.touched && passwordControl.invalid"
      >
        <label for="password">
          {{ 'common.controls.password.label' | translate }}
        </label>

        <input
          type="password"
          formControlName="password"
          id="password"
          [placeholder]="'common.controls.password.placeholder' | translate"
        />

        <form-error-messages
          [showErrors]="passwordControl.touched"
          [control]="passwordControl"
        />
      </div>

      <div class="form-group forgot-password-link">
        <a
          [routerLink]="['/', 'forgotPassword']"
          class="login-forgot-password"
        >
          {{ 'common.controls.password.forgotPassword' | translate }}
        </a>
      </div>
    }

    <p></p>

    <!-- Submit button -->
    <button
      type="submit"
      [appBtnLoading]="isLoading()"
      [disabled]="signInForm.invalid"
      class="btn btn-primary submit-btn submit-btn-wide"
    >
      @if (passwordControl.enabled) {
        {{ 'common.buttons.signIn' | translate }}
      } @else {
        {{ 'common.buttons.continue' | translate }}
      }
    </button>

    <!-- Sign Up link -->
    <div class="login-form_signup-link">
      <span>{{ 'signIn.dontHaveAcc' | translate }}</span>

      <a [routerLink]="['/signup']">
        {{ 'common.buttons.signUp' | translate }}
      </a>
    </div>
  </form>
</div>
