<div class="notifications-dropdown-menu">
  <div class="header">
    <div class="notifications_label">
      <span>{{ 'notifications.notifications' | translate }}</span>

      @if (totalNotifications > 0) {
        <div>
          <span>{{ totalNotificationsNumber }}</span>
        </div>
      }
    </div>

    @if (totalNotifications > 0) {
      <button
        (click)="dismissAllNotifications()"
        [disabled]="isImpersonating"
        [appFocusable]
        class="notifications_dismiss-all-btn"
      >
        {{ 'notifications.dismissAll' | translate }}
      </button>
    }
  </div>

  <scrollable-area
    (scrollDown)="onNotificationsScroll()"
    [maxHeight]="500"
  >
    @if (totalNotifications === 0) {
      <div class="notifications_placeholder">
        {{ 'notifications.noNotificationsMsg' | translate }}
      </div>
    }

    @for (notification of loadedNotifications; track notification) {
      <div class="notifications_item">
        <div class="notifications_item-header">
          @if (notification.type === 'System') {
            <span>
              {{ 'notifications.systemNotification' | translate }}
            </span>
          }
          @if (notification.type === 'User' || notification.type === 'BillingAdmin') {
            <span>
              {{ 'notifications.userNotification' | translate }}
            </span>
          }
          @if (notification.type === 'Reminder') {
            <span>
              {{ 'notifications.reminder' | translate }}
            </span>
          }
          <button
            (click)="dismissNotification(notification)"
            [disabled]="isImpersonating"
            [appFocusable]
          >
            <img
              alt="dismiss"
              appAssetSrc="assets/svg/header/notification-dismiss.svg"
            />
          </button>
        </div>
        <div class="notifications_item-content">
          <div class="notification_sender-logo">
            @if (notification.type === 'System') {
              <img
                class="notification_sender-logo-image"
                appAssetSrc="assets/svg/common/clover.svg"
              />
            }
            @if (notification.type === 'User' || notification.type === 'BillingAdmin') {
              <user-logo
                [hideStatus]="true"
                [user]="notification?.metadata?.sender"
              />
            }
            @if (notification.type === 'Reminder') {
              <div class="notifications_reminder-icon">
                <user-logo
                  [hideStatus]="true"
                  [user]="notification?.metadata?.sender"
                />
                <company-logo [company]="notification?.metadata?.sender.company" />
              </div>
            }
          </div>
          <div>
            @if (notification.type === 'System') {
              <div>
                <div class="notification-title">
                  {{ notification?.metadata?.title }}
                </div>
              </div>
            }
            @if (notification.type === 'Reminder') {
              <div>
                <div class="notification-title">
                  {{ notification?.metadata.subject }}
                </div>
              </div>
            }
            @if (notification.type === 'User') {
              <div class="notification-title">
                <div class="notification-title">
                  {{ notification?.metadata?.sender?.firstName + ' ' + notification?.metadata?.sender?.lastName }}
                </div>
                <div class="notification-subtitle">
                  {{ notification?.metadata?.sender?.company?.name }}
                </div>
              </div>
            }
            @if (notification.type === 'BillingAdmin') {
              <div class="notification-title">
                <div class="notification-title">
                  {{ 'notifications.billingAdminTitle' | translate }}
                </div>
              </div>
            }
            <div
              [innerHtml]="getNotificationText(notification)"
              class="notification-message"
            ></div>
            <div class="notification-date">
              {{ notification?.createdAt | ccDateFormatDistance: { addSuffix: true } }}
            </div>
          </div>
        </div>
      </div>
    }
  </scrollable-area>
</div>
