import { Component, type OnInit, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { PAGE_URL } from '@core/constants/page-url';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { type Company } from '@core/models/company';
import { ToastrService } from '@core/services/toastr.service';
import { RadioComponent } from '@design/forms/radio/radio.component';

import {
  FirstQuestionAnswers,
  FirstQuestionAnswerTypes,
  SecondQuestionAnswers,
  SecondQuestionAnswerTypes,
} from './interview-answers';
import { LegacyLoaderComponent } from '../../../core/components/loader/loader.component';
import { AuthStepIndicatorComponent } from '../../auth-step-indicator/auth-step-indicator.component';
import { AuthService } from '../../auth.service';

@UntilDestroy()
@Component({
  selector: 'app-interview',
  templateUrl: './interview.component.html',
  styleUrls: ['./interview.component.scss'],
  standalone: true,
  imports: [
    AssetSrcDirective,
    AuthStepIndicatorComponent,
    LegacyLoaderComponent,
    ButtonLoadingDirective,
    RadioComponent,
    FormsModule,
    TranslateModule,
  ],
})
export class InterviewComponent implements OnInit {
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly authService = inject(AuthService);
  private readonly toastr = inject(ToastrService);
  public isLoaded = false;
  public isSendingAnswer = false;
  public currentStep = 1;
  public senderCompanyData: Company;
  public steps = {
    start: 1,
    interviewA: 2,
    interviewB: 3,
    invitationDeclined: 4,
  };
  public isCompanyAssosiation = true;
  public selectedAnswerA: string;
  public selectedAnswerB: string;
  private invitationToken: string;

  public get answersA() {
    return FirstQuestionAnswers;
  }

  public get answersB() {
    return this.selectedAnswerA === FirstQuestionAnswerTypes.Broker
      ? SecondQuestionAnswers.filter((answer) => answer.id !== SecondQuestionAnswerTypes.Brokerage)
      : SecondQuestionAnswers;
  }

  public get companyName(): string {
    return this.senderCompanyData?.name;
  }

  public ngOnInit(): void {
    const params = this.activatedRoute.snapshot.queryParams;

    if (!params.invitation_token) {
      this.router.navigate(['404']);
    }

    this.authService
      .getInvitationInfo(params.invitation_token)
      .pipe(
        untilDestroyed(this),
        catchError((err) => {
          const errorCode = err?.error?.errors?.[0]?.errorCode;
          this.authService.navigateAfterError(errorCode);
          return of(err);
        }),
        finalize(() => (this.isLoaded = true)),
      )
      .subscribe((res) => {
        this.invitationToken = params.invitation_token;
        this.senderCompanyData = res.senderCompany;
      });
  }

  public start(): void {
    this.currentStep = this.steps.interviewA;
  }

  public continue(): void {
    this.isSendingAnswer = true;
    if (this.currentStep === this.steps.interviewA) {
      this.authService
        .sendFirstQuestionAnswer({
          token: this.invitationToken,
          answer: this.selectedAnswerA,
        })
        .pipe(
          untilDestroyed(this),
          map(() => {
            if (this.selectedAnswerA === FirstQuestionAnswerTypes.NotRelated) {
              this.currentStep = this.steps.invitationDeclined;
            } else {
              this.currentStep = this.steps.interviewB;
            }
          }),
          catchError((err) => {
            this.toastr.displayServerErrors(err);
            return of(err);
          }),
          finalize(() => (this.isSendingAnswer = false)),
        )
        .subscribe();
    } else {
      this.authService
        .sendSecondQuestionAnswer({
          token: this.invitationToken,
          answer: this.selectedAnswerB,
        })
        .pipe(
          map(() => {
            this.navigateAfterAnswers();
          }),
          finalize(() => (this.isSendingAnswer = false)),
          catchError((err) => {
            this.toastr.displayServerErrors(err);
            return of(err);
          }),
        )
        .subscribe();
    }
  }

  public goBack(): void {
    this.currentStep = this.currentStep - 1;
  }

  private navigateAfterAnswers(): void {
    switch (this.selectedAnswerB) {
      case SecondQuestionAnswerTypes.Distributor:
      case SecondQuestionAnswerTypes.RetailerOrReseller:
        this.router.navigate([PAGE_URL.PROFILE_SETUP]);
        break;

      default:
        this.router.navigate(['/invitationSignup'], {
          queryParamsHandling: 'merge',
        });
        break;
    }
  }
}
